import React, { PropsWithChildren } from 'react'

import { QueryClientProvider } from 'react-query'

import { ModalProvider, NotificationProvider } from '@inter/inter-ui'

import { GlobalStateProvider } from './global-state'
import { queryClient } from './query-client'
import StateInspector from './state-inspector'

const AppProviders = ({ children }: PropsWithChildren<unknown>) => (
  <GlobalStateProvider>
    <StateInspector>
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <NotificationProvider>{children}</NotificationProvider>
        </ModalProvider>
      </QueryClientProvider>
    </StateInspector>
  </GlobalStateProvider>
)

export default AppProviders
