import styled from 'styled-components'

type ButtonProps = {
  selected: boolean
}
export const Container = styled.button<ButtonProps>`
  padding: 0.75rem;
  height: 90px;
  width: 90px;

  border: ${(props) =>
    props.selected ? '2px solid var(--primary500)' : '1px solid var(--gray200)'};
  background-color: transparent;
  border-radius: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`
