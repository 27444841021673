import React, { useCallback } from 'react'

import { Button, Text } from '@inter/inter-ui'
import { navigateHome } from '@/services/bridge'
import Error from '@interco/icons/bidis/v2/action/stats/error'

import * as S from './styles'

export const ConnectionError = () => {
  const returnHomePage = useCallback(() => {
    navigateHome()
  }, [])

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Error>
            <Error height={64} width={64} />
          </S.Error>
          <Text variant="headline-h1" semiBold>
            Erro na Conexão
          </Text>

          <S.Description>
            <Text variant="body-3" bold colorWeight={400} as="p">
              Não foi possível estabelecer a conexão de dados. Tente mais tarde. Seus dados não
              foram armazenados.
            </Text>
          </S.Description>

          <Button onClick={returnHomePage}>Entendi</Button>
        </S.Content>
      </S.Container>
    </>
  )
}
