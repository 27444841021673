import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  height: 100%;

  span {
    margin-top: 1.5rem;
  }

  button {
    margin-bottom: 1.5rem;
    margin-top: auto;
    width: 100%;
  }
`

export const Description = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  line-height: 1;

  p {
    margin-top: 1rem;
  }
`
export const Check = styled.div`
  margin-top: auto;

  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--success500);

  svg {
    path {
      fill: #ffffff;
    }
  }
`
