import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const Description = styled.div`
  margin-top: 0.5rem;
  padding: 0 1.5rem;
  text-align: center;
  line-height: 1;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: var(--gray100);

  svg {
    path {
      stroke: var(--gray500);
    }
    circle {
      stroke: var(--gray300);
    }
  }
`
