export enum BankListEnum {
  BRADESCO = 'bradesco',
  BANCO_DO_BRASIL = 'bancoDoBrasil',
  BTG_PACTUAL = 'btgPactual',
  CAIXA_ECONOMICA = 'caixaEconomica',
  NUBANK = 'nubank',
  ITAU = 'itau',
  ORAMA = 'orama',
  SANTANDER = 'santander',
  XP = 'xp',
  SICOOB = 'sicoob',
}
