import React, { useCallback, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Button, Text } from '@inter/inter-ui'
import { Header } from '@/components/Header'
import {
  BancoDoBrasilImg,
  BradescoImg,
  BtgPactualImg,
  CaixaEconomicaImg,
  ItauImg,
  NubankImg,
  OramaImg,
  SantanderImg,
  SicoobImg,
  XpImg,
} from '@/assets/images'
import { RoutesNames } from '@/routes/routes'

import * as S from './styles'
import { BankItem } from './BankItem'
import { BankListEnum } from './type'

type ActiveBankProps = {
  name: BankListEnum
  image: string
}
export const SelectBank = () => {
  const history = useHistory()
  const [activeBank, setActiveBank] = useState<ActiveBankProps>()

  const banksList = [
    { name: BankListEnum.BRADESCO, image: BradescoImg },
    { name: BankListEnum.BANCO_DO_BRASIL, image: BancoDoBrasilImg },
    { name: BankListEnum.BTG_PACTUAL, image: BtgPactualImg },
    { name: BankListEnum.CAIXA_ECONOMICA, image: CaixaEconomicaImg },
    { name: BankListEnum.NUBANK, image: NubankImg },
    { name: BankListEnum.ITAU, image: ItauImg },
    { name: BankListEnum.ORAMA, image: OramaImg },
    { name: BankListEnum.SANTANDER, image: SantanderImg },
    { name: BankListEnum.XP, image: XpImg },
    { name: BankListEnum.SICOOB, image: SicoobImg },
  ]

  const changePage = useCallback(() => {
    history.push({
      pathname: `${RoutesNames.LOGIN_BANK}/${activeBank?.name}`,
      state: activeBank?.name,
    })
  }, [activeBank?.name, history])

  const onSelect = useCallback((bankItem) => {
    setActiveBank(bankItem)
  }, [])

  return (
    <>
      <S.Container>
        <Header onLeftIconClick={() => history.goBack()} title="Selecione a instituição" />
        <S.Content>
          <Text variant="body-3">
            Selecione o banco que você quer que seja analisado para tentar um aumento de crédito.
          </Text>

          <S.BankList>
            {banksList.map(({ image, name }) => (
              <BankItem
                key={image}
                bankImage={image}
                selected={!!activeBank && activeBank.name === name}
                onClick={() => onSelect({ name, image })}
              />
            ))}
          </S.BankList>

          <Button disabled={!activeBank} onClick={changePage}>
            Continuar
          </Button>
        </S.Content>
      </S.Container>
    </>
  )
}
