import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  padding: 0 2rem;
`
type ButtonProps = {
  color: string
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-bottom: 1.5rem;
`

export const CustomButton = styled.div<ButtonProps>`
  margin-top: auto;
  width: 100%;

  > button {
    width: 100%;
    background: ${(props) => props.color};

    &:focus {
      background: ${(props) => props.color};
    }
    &:hover {
      background: ${(props) => props.color};
    }
  }
`

export const BankImage = styled.div`
  margin-top: calc(56px + 1.5rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: auto;
  }
`
export const Form = styled.form`
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    width: 100%;
  }

  span {
    text-align: right;
    color: var(--error500);
  }
`
