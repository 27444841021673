import styled, { css } from 'styled-components'

export const headerStyles = ({ additionalPadding = 0 }) => css`
  position: relative;
  padding-top: ${() => `${54 + additionalPadding}px`};
`

export const Container = styled.div`
  width: 100vw;
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: var(--white100);
  padding: 0 24px;
  top: 0;
  left: 0;
  z-index: var(--zIndexMaximum);

  h1,
  h2,
  h3,
  span,
  p {
    align-self: center;
    margin: auto;
  }
`
