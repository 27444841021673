import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0% { transform: scale(1); }
  14% { transform: scale(1.3); }
  28% { transform: scale(1); }
  42% { transform: scale(1.3); }
  70% { transform: scale(1); }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > svg:hover {
    animation: ${animation} 1.3s linear infinite;
  }
`

export const Image = styled.div`
  display: flex;
  margin-top: 56px;

  img {
    width: 100vw;
  }
`

export const Separator = styled.div`
  padding: 1.5rem;
  width: 100%;

  > div {
    border: 1px dashed #e6e6e6;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    span {
      text-align: left;
    }
  }

  .description {
    > span {
      text-align: left;
      margin-left: 2.5rem;
      margin-top: 0.25rem;
      color: var(--gray400);

      span {
        margin: 0;
      }
    }
  }

  > button {
    margin-top: 1rem;
  }
`

export const TermsAndConditions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: auto;

  a {
    text-decoration: underline;
  }
`
