import { IWbHttpOptions, WbSessionActions } from 'inter-webview-bridge'

enum CustomActions {
  GET_SUPER_APP_TYPE = 'getSuperAppType',
  DOWNLOAD_TERM = 'downloadTerm',
  NAVIGATE_HOME = 'navigateHome',
  NAVIGATE_TO_HOME = 'navigateToHome',
  OPEN_NATIVE_BROWSER = 'openNativeBrowser',
}

export const Actions = {
  ...WbSessionActions,
  ...CustomActions,
}

type CommonArguments = {
  endpoint: string
  headers?: Record<string, string>
  options?: IWbHttpOptions
}

type GetOrDelete = CommonArguments & {
  method: 'GET' | 'DELETE'
}

export type PostOrPut = CommonArguments & {
  method: 'POST' | 'PUT'
  body?: Record<string, unknown>
}

export type Request = GetOrDelete | PostOrPut

export enum DeepLinks {
  CARD = 'bancointer://cartoes',
  HOME = 'bancointer://login',
}
