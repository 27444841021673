import React from 'react'

import { Icon, Text } from '@inter/inter-ui'

import * as S from './styles'

export type HeaderProps = {
  title?: string
  showLeftIcon?: boolean
  onLeftIconClick?: () => void
}

export const Header = ({ title, onLeftIconClick, showLeftIcon = true }: HeaderProps) => (
  <S.Container>
    {showLeftIcon && <Icon name="left-arrow" color="var(--primary500)" onClick={onLeftIconClick} />}

    <Text semiBold variant="headline-h3">
      {title}
    </Text>
  </S.Container>
)
