import React, { useCallback, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'
import { Header } from '@/components/Header'
import { Button, Input, Text } from '@inter/inter-ui'
import {
  bbToInter,
  bradescoToInter,
  btgToInter,
  caixaToInter,
  itauToInter,
  nubankToInter,
  oramaToInter,
  santanderToInter,
  sicoobToInter,
  xpToInter,
} from '@/assets/images'
import { Hide, Show } from '@/components/Icons'

import * as S from './styles'

export const LoginBank = () => {
  const history = useHistory()
  const location = useLocation<string>()
  const params: string = location.state

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const bankProps = useCallback(() => {
    switch (params) {
      case 'bradesco':
        return { image: bradescoToInter, color: '#F0152D', name: 'Bradesco' }
      case 'bancoDoBrasil':
        return { image: bbToInter, color: '#2A458C', name: 'Banco do Brasil' }
      case 'btgPactual':
        return { image: btgToInter, color: '#2A458C', name: 'BTG Pactual' }
      case 'caixaEconomica':
        return { image: caixaToInter, color: '#2A458C', name: 'Caixa Econômica' }
      case 'itau':
        return { image: itauToInter, color: '#2E3191', name: 'Itaú' }
      case 'nubank':
        return { image: nubankToInter, color: '#9514D1', name: 'Nubank' }
      case 'orama':
        return { image: oramaToInter, color: '#518F25', name: 'Órama' }
      case 'santander':
        return { image: santanderToInter, color: '#F0152D', name: 'Santander' }
      case 'sicoob':
        return { image: sicoobToInter, color: '#003D44', name: 'Sicoob' }
      case 'xp':
        return { image: xpToInter, color: '#000000', name: 'XP' }
      default:
        return {}
    }
  }, [params])

  const changePage = useCallback(() => {
    history.push(RoutesNames.CONNECT)
  }, [history])

  const onSubmit = useCallback(() => {
    changePage()
    setValue('agencia', '')
    setValue('conta', '')
    setValue('senha', '')
  }, [changePage, setValue])

  const props = bankProps()

  return (
    <>
      <S.Container>
        <Header onLeftIconClick={() => history.goBack()} title="Dados da conta" />
        <S.Content>
          <S.BankImage>
            <img src={props.image} alt="" />
          </S.BankImage>
          <Text variant="headline-h2" semiBold colorWeight={500} style={{ marginTop: '1.5rem' }}>
            Informe os dados da sua conta no {props.name}
          </Text>
          <Text variant="body-3" style={{ marginTop: '0.5rem' }}>
            Seus dados são criptografados e estão 100% seguros
          </Text>

          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register('agencia', { required: true })}
              id="agencia"
              label="Agência"
              placeholder="1234"
              infoText={errors.agencia?.type === 'required' ? 'Campo obrigatório' : ''}
              onlyNumber
            />

            <Input
              {...register('conta', { required: true })}
              id="conta"
              label="Conta (sem dígito)"
              placeholder="123451"
              infoText={errors.conta?.type === 'required' ? 'Campo obrigatório' : ''}
              onlyNumber
            />
            <Input
              {...register('senha', { required: true })}
              id="senha"
              label="Senha"
              placeholder="Digite a senha"
              infoText={errors.senha?.type === 'required' ? 'Campo obrigatório' : ''}
              iconRight={
                isVisible ? (
                  <Show
                    height={24}
                    width={24}
                    color={`${props.color}`}
                    onClick={() => setIsVisible(!isVisible)}
                  />
                ) : (
                  <Hide
                    height={24}
                    width={24}
                    color={`${props.color}`}
                    onClick={() => setIsVisible(!isVisible)}
                  />
                )
              }
              type={isVisible ? 'text' : 'password'}
            />
            <S.CustomButton color={`${props.color}`}>
              <Button type="submit">Continuar</Button>
            </S.CustomButton>
          </S.Form>
        </S.Content>
      </S.Container>
    </>
  )
}
