import React, { useCallback } from 'react'

import { Button, Text } from '@inter/inter-ui'
import { navigateHome } from '@/services/bridge'
import { Check } from '@/assets/icons'

import * as S from './styles'

export const ReturnHome = () => {
  const returnHomePage = useCallback(() => {
    navigateHome()
  }, [])

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Check>
            <Check height={32} width={32} />
          </S.Check>
          <Text variant="headline-h1" semiBold>
            Dados em análise
          </Text>

          <S.Description>
            <Text variant="body-3" bold colorWeight={400}>
              Recebemos seus dados do outro banco informado. Eles estão sendo analisados para
              liberação de crédito no Inter.
            </Text>
            <Text variant="body-3" bold colorWeight={500} as="p">
              Confira seu email dentro de 7 dias.
            </Text>
          </S.Description>

          <Button onClick={returnHomePage}>Retornar a página inicial</Button>
        </S.Content>
      </S.Container>
    </>
  )
}
