import InterWebviewBridge, {
  interWbNavigate,
  interWbSession,
  WbEnvironments,
} from 'inter-webview-bridge'

import * as Types from './types'

export const iWb = InterWebviewBridge.getInstance()
export const environment = iWb.getEnvironment()
export const inApp = environment !== WbEnvironments.BROWSER

export const requestGoBack = async (): Promise<unknown> => {
  if (inApp) {
    try {
      await interWbNavigate.requestGoBack()
      return Promise.resolve()
    } catch (error) {
      return error
    }
  }

  return Promise.resolve(undefined)
}

export const navigateHome = async (): Promise<void> => {
  if (inApp) {
    if (iWb.getEnvironment() === WbEnvironments.ANDROID) {
      return iWb.execute<void, undefined>({
        action: Types.Actions.NAVIGATE_TO_HOME,
      })
    }
    if (iWb.getEnvironment() === WbEnvironments.IOS) {
      return iWb.execute<void, undefined>({
        action: Types.Actions.NAVIGATE_HOME,
      })
    }
  }
  return Promise.resolve(undefined)
}

export const requestAnalytics = async (name: string, params?: Record<string, string>) => {
  if (inApp) {
    try {
      await interWbSession.requestAnalytics(name, params)
    } catch (error) {
      window.console.error(error)
    }
  }
}
