import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  padding: 0 2rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 1.5rem;

  margin-top: calc(56px + 1.5rem);

  span {
    text-align: center;
  }

  button {
    margin-top: auto;
  }
`
export const BankList = styled.div`
  margin-top: 1.5rem;

  display: grid;
  grid-template-columns: repeat(3, auto);

  grid-row-gap: 1rem;
  grid-column-gap: 0.75rem;
`
