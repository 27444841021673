import * as S from './styles'

type BankItemProps = {
  bankImage: string
  selected: boolean
  onClick: () => void
}

export const BankItem = ({ bankImage, onClick, selected }: BankItemProps) => (
  <>
    <S.Container selected={selected} onClick={onClick}>
      <img src={bankImage} alt="" />
    </S.Container>
  </>
)
