/* istanbul ignore file */
import React, { useEffect } from 'react'

import ReactDOM from 'react-dom'

import { IS_DEVELOPMENT } from '@/common/constants'
import { initTheme, Theme } from '@inter/inter-ui'

import AppProviders from './contexts'
import Routes from './routes'

import './styles/global.scss'

if (IS_DEVELOPMENT) {
  import('./common/mocks/handlers')
}

const App = () => {
  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
