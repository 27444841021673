import React, { useCallback, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'
import { Text } from '@inter/inter-ui'
import { Loading } from '@/components/Loading'

import * as S from './styles'

export const Connect = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const changeLoading = setTimeout(() => {
    setIsLoading(false)
    history.push(RoutesNames.CONNECTION_ERROR)
  }, 7000)

  const changePage = useCallback(() => {
    changeLoading
  }, [changeLoading])

  useEffect(() => {
    changePage
  }, [changePage])

  return (
    <>
      <S.Container>
        <S.Content>
          <S.LoadingContainer>
            {isLoading ? <Loading width={32} height={32} /> : <div style={{ height: '130px' }} />}
          </S.LoadingContainer>
          <Text variant="headline-h1" semiBold style={{ marginTop: '1.5rem' }}>
            Estabelecendo Conexão
          </Text>

          <S.Description>
            <Text variant="body-3" bold colorWeight={400}>
              Estamos conectando na sua conta do banco informado. Não se preocupe, seus dados estão
              criptografados pra maior segurança e privacidade.
            </Text>
          </S.Description>
        </S.Content>
      </S.Container>
    </>
  )
}
