// import Main from '@/main'
import { Connect } from '@/pages/Connect'
import { LoginBank } from '@/pages/LoginBank'
import { Onboarding } from '@/pages/Onboarding'
import { ReturnHome } from '@/pages/ReturnHome'
import { SelectBank } from '@/pages/SelectBank'
import { ConnectionError } from '@/pages/ConnectionError'

export const RoutesNames = {
  ROOT: '/',
  ONBOARDING: '/onboarding',
  SELECT_BANK: '/selecionar-banco',
  LOGIN_BANK: '/login',
  CONNECT: '/estabelecendo-conexao',
  RETURN_HOME: '/conexao-estabelecida',
  CONNECTION_ERROR: '/conexao-erro',
}

export default [
  { path: RoutesNames.ROOT, exact: true, component: Onboarding },
  { path: RoutesNames.SELECT_BANK, exact: true, component: SelectBank },
  { path: RoutesNames.LOGIN_BANK, component: LoginBank },
  { path: RoutesNames.CONNECT, component: Connect },
  { path: RoutesNames.RETURN_HOME, component: ReturnHome },
  { path: RoutesNames.CONNECTION_ERROR, component: ConnectionError },
]
