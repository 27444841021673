import React, { useCallback, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'
import { Header } from '@/components/Header'
import { Button, Checkbox, Link, Text } from '@inter/inter-ui'
import { requestGoBack } from '@/services/bridge'

import HeroImg from '../../assets/images/Hero.svg'
import * as S from './styles'
import { CardAdd, Lock } from '../../components/Icons'

export const Onboarding = () => {
  const history = useHistory()
  const [checked, setChecked] = useState<boolean>(false)

  const changePage = useCallback(() => {
    history.push(RoutesNames.SELECT_BANK)
  }, [history])

  const backPage = useCallback(() => {
    requestGoBack()
  }, [])

  return (
    <>
      <S.Container>
        <Header onLeftIconClick={backPage} title="Conecte sua conta" />
        <S.Image>
          <img src={HeroImg} alt="Hero" />
        </S.Image>

        <S.Content>
          <Text variant="headline-h1" semiBold>
            Como funciona?
          </Text>
          <div style={{ marginTop: '1rem' }}>
            <CardAdd width={24} height={24} color="var(--gray500)" />
            <Text variant="body-3" bold colorWeight={500}>
              Aumento do limite de crédito
            </Text>
          </div>
          <div className="description">
            <Text variant="body-3" bold colorWeight={500} color="typography">
              Analisamos seu perfil em outros bancos, aumentando suas chances de ter um maior limite
              de crédito no Inter.
            </Text>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <Lock width={24} height={24} color="var(--gray500)" />
            <Text variant="body-3" bold colorWeight={500}>
              Segurança garantida
            </Text>
          </div>
          <div className="description" style={{ marginBottom: '3rem' }}>
            <Text variant="body-3" bold colorWeight={500} color="typography">
              Os dados compartilhados são protegidos por criptografia e estão 100% seguros.
            </Text>
          </div>

          <S.TermsAndConditions>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
            <Text variant="body-3" bold colorWeight={500} color="typography">
              Li e concordo com os{' '}
              <Link
                variant="body-3"
                bold
                colorWeight={500}
                color="primary"
                href="https://www.bancointer.com.br/politica-de-privacidade/privacidade/"
              >
                termos e condições
              </Link>{' '}
              e{' '}
              <Link
                variant="body-3"
                bold
                colorWeight={500}
                color="primary"
                href="https://www.bancointer.com.br/politica-de-privacidade/privacidade/"
              >
                Politica de Privacidade
              </Link>{' '}
              do Inter.
            </Text>
          </S.TermsAndConditions>
          <Button disabled={!checked} onClick={changePage}>
            Continuar
          </Button>
        </S.Content>
      </S.Container>
    </>
  )
}
